import React, {Component} from 'react';
import axios from "axios";
import Fullscreen from 'react-fullscreen-crossbrowser';
import fscreen from 'fscreen';
import { IoIosExpand } from 'react-icons/io';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import 'react-popupbox/dist/react-popupbox.css';
import YouTube from 'react-youtube';

import SplashScreen from './SplashScreen';
import Home from './Home';
import CheckIn from './CheckIn';
import CheckOut from './CheckOut';
import Setup from './Setup';
import AbortSession from './AbortSession';
import Rescan from './Rescan';
import Branding from './Branding';

import QRCode from 'qrcode';

import FreeVersionBadge from './images/free-version-badge.jpg';
import FreeSMSTrialBadge from './images/free-sms-trial-badge.jpg';

// https://github.com/LinkedInLearning/react-spas-774920/tree/02_01e
// domain: 'https://dev-visitorsfortrello.visitorsfortrello.com',
// domain: 'https://visitorsfortrello.com',

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: 'https://visitorsfortrello.com',
            displaySettings: [],
            colors: {
                colorFront: '',
                colorFrontShadow: '',
                colorFrontShadowTransparency: '',
                colorBtnText: '',
                colorBack: '',
                colorPopText: '',
                colorPopBack: '',
                colorPopTransparency: '',
                colorInputBorder: '',
                colorInputTransparency: '',
            },
            background: {},
            backgroundType: '',
            backgroundColor: '',
            backgroundImage: '',
            companyFormField : 1,
            phoneFormField: 1,
            mailFormField: 1,
            hostFormField: 1,
            updated: '',
            fullContent: true,
            host: '',
            hostUrl: '',
            hostCode: null,
            translations: [],
            languages: [],
            languageSelect: 0,
            languageDefault: '',
            homeMessage: '',
            terms: '',
            phoneCodes: [],
            isFullscreenEnabled: false,
            goFullScreenStyle: {},
            screen: '',
            rescan: false,
            mainStyle: {},
            qrReader: 0,
            qrFlip: 0,
            qrFrontRear: 'front',
            qrImageURL: '',
            touchless: 0,
            hasAccount: 1,
            freeSMSPoints: 0,
            branding: {
                brand_text_color: '',
                brand_background_color: '',
                brand_background_transparency: '',
                brand_image: '',
            },
            shouldPauseVideo: true,
            appStyle: {}

        };

        this.youtubeRef = React.createRef();


    }


    componentDidMount() {

        const host = this.getParameterByName('host');
        this.setState({ hostUrl: host });

        const touchless = this.getParameterByName('touchless');

        if(touchless !== null) {
            this.setState({ touchless: touchless });
        }

        const displaySettings   = JSON.parse(localStorage.getItem('displaySettings'));

        if(this.state.screen === "setup" ) {

            if(displaySettings.host_code) {
                this.setState({ screen: 'abort-session'});
            }

        } else {

            if(host !== null && (displaySettings === null || (displaySettings !== null && host !== displaySettings.host_code))) {

                this.setState({ hostCode: host });

                if(host && host.substring(0,2) === "V-"){
                    this.setState({ rescan: true });
                }

                const data = {
                    host_code: host,
                }

                this.loadClientValues(data).then(function(responseObject) {

                    localStorage.setItem('displaySettings', JSON.stringify(responseObject.data));

                    var response = responseObject.data;

                    if(response.client_accepted === '1') {

                        console.log('client accepted');
                        this.setDisplayValues(response);

                    } else {

                        if(this.state.rescan) {

                            this.setState({ colors: {
                                colorFront: '#4f4f4f',
                                colorFrontShadow: '#000',
                                colorFrontShadowTransparency: '0',

                            }});

                            this.setState({ translations: response.translations });

                            this.changeScreen('rescan');

                        } else {
                            this.changeScreen('setup');
                        }

                    }
                }.bind(this));

            } else {

                this.loadClient();
            }


        }

    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        //name = name.replace(/[\[\]]/g, "\\$&");
        //name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }


    validateAccess() {

        setInterval(function(){

            if(this.state.screen === 'home') {

                //console.log("Check if client still is accepted.");

                var data = JSON.parse(localStorage.getItem('displaySettings'));

                axios({
                    method: 'post',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    url: this.state.domain + '/core/connect.php?action=connectClient&fullContent=' + this.state.fullContent,
                    data: data

                }).then((responseObject) => {

                    var response = responseObject.data;

                    if (response.client_accepted === 0) {

                        clearInterval(this.validateAccessTimer);
                        localStorage.clear();

                        document.body.style.backgroundImage = "";

                        this.setState({ screen: 'setup'});

                    } else {

                        if(response.updated !== this.state.updated || this.state.hasAccount !== response.has_account) {

                            if(this.state.fullContent === true){

                                localStorage.setItem('displaySettings', JSON.stringify(response));
                                this.setResponseStates(response);
                                this.setState({ fullContent: false });

                            } else {
                                this.setState({ fullContent: true });
                            }

                        } else {
                            this.setState({ fullContent: false });
                        }

                    }

                }).catch((error) => {

                    console.log(error)

                });

            }

        }.bind(this), 10000);

    }

    setup(){

        clearInterval(this.splashscreenWait);

        document.body.style.backgroundImage = "";

        const displaySettings   = JSON.parse(localStorage.getItem('displaySettings'));

        if(displaySettings.host_code) {
            this.setState({ screen: 'abort-session'});
        } else {
            this.setState({ screen: 'setup'});
        }
    }




    loadClient() {

        this.setState({ hostCode: null});

        const data = JSON.parse(localStorage.getItem('displaySettings'));

        this.loadClientValues(data).then(function(responseObject) {

            const response = responseObject.data;

            this.setDisplayValues(response);

        }.bind(this));

    }


    loadClientValues(data){


        return new Promise(function(resolve) {

            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.state.domain + '/core/connect.php?action=connectClient',
                data: data

            }).then((response) => {

                resolve(response);

            }).catch((error) => {

                console.log(error)

            });
        }.bind(this));

    }

    setDisplayValues(data) {

        localStorage.setItem('displaySettings', JSON.stringify(data));

        this.setResponseStates(data);
        this.setState({ screen: 'splashscreen'});

        this.splashscreenWait = setTimeout(function () {

            clearInterval(this.splashscreenWait);

            if(this.state.hostCode && this.state.hostCode.substring(0,2) !== "V-" && this.state.hostCode !== data.host_code){
                this.setState({ screen: 'abort-session'});

            } else if(data.client_accepted !== '1' || data.id === '-1') {

                this.setState({ screen: 'setup'});

            } else {

                this.setState({ screen: 'home'});
                this.validateAccess();

            }

        }.bind(this), 2000);



    }

    setResponseStates(response){

        this.setState({ colors: {
            colorFront: response.color_front,
            colorFrontShadow: response.color_front_shadow,
            colorFrontShadowTransparency: response.color_front_shadow_transparency,
            colorBtnText: response.color_btn_text,
            colorBack: response.color_back,
            buttonTransparency: response.button_transparency,
            colorPopText: response.color_pop_text,
            colorPopBack: response.color_pop_back,
            colorPopTransparency: response.color_pop_transparency,
            colorInputBorder: response.color_input_border,
            colorInputBorderTransparency: response.color_input_border_transparency,
            }
        });

        this.setState({ terms: response.terms});
        this.setState({ qrImage: Number(response.qr_image)});
        this.setState({ qrReader: Number(response.qr_reader)});
        this.setState({ qrFlip: Number(response.qr_flip)});
        this.setState({ qrFrontRear: response.qr_front_rear});

        const backgroundType        = Number(response.background_type);

        let backgroundColor         = '#000';
        let backgroundImage         = '';
        let backgroundBlendMode     = '';

        if(backgroundType === -1) {
            backgroundImage         = this.state.domain + "/backgrounds/visitors-background.jpg";
            backgroundBlendMode     = "normal";

        } else if(backgroundType === -2) {
            backgroundImage         = "data:image/png;base64," + response.background_image;
            backgroundBlendMode     = "normal";

        } else if(backgroundType === -3) {
            backgroundColor         = response.background_color;

        } else if(backgroundType >= 1 && backgroundType < 99) {
            backgroundColor         = response.background_color;
            backgroundImage         = this.state.domain + "/backgrounds/texture_" + backgroundType + ".png";
            backgroundBlendMode     = "overlay";

        } else if(backgroundType >= 100 && backgroundType < 199) {
            backgroundColor         = response.background_color;
            backgroundImage         = this.state.domain + "/backgrounds/texture_" + backgroundType + ".png";
            backgroundBlendMode     = "multiply";

        }

        if(backgroundType === 3) {
            document.body.style.backgroundColor = backgroundColor;
        } else {

        }
        this.onBackgroundImageLoaded(backgroundImage, backgroundColor, backgroundBlendMode);

        this.setState({ companyFormField: Number(response.company_form_field)});
        this.setState({ phoneFormField: Number(response.phone_form_field)});
        this.setState({ mailFormField: Number(response.mail_form_field)});
        this.setState({ hostFormField: Number(response.host_form_field)});

        this.setState({ translations: response.translations });
        this.setState({ languages: response.languages });
        this.setState({ languageSelect: response.language_select });
        this.setState({ languageDefault: response.language_default });

        this.setState({ phoneCodes: response.phone_codes });

        this.setState({ updated: response.updated });

        this.setState({ branding: response.branding });

        this.setState({ hasAccount: response.has_account });
        this.setState({ freeSMSPoints: response.free_sms_points });

        if(fscreen.fullscreenEnabled) { // Check if device allow full screen

            this.setState({ goFullScreenStyle: {
                color: response.color_front,
                textShadow:  '0px 4px 3px ' + response.color_front_shadow + response.color_front_shadow_transparency,
                display: 'block',
            }
            });

            const timer = setInterval(function(){
                this.setState({ goFullScreenStyle: {
                    color: response.color_front,
                    textShadow:  '0px 4px 3px ' + response.color_front_shadow + response.color_front_shadow_transparency,
                    display: 'none',
                }
                });
                clearInterval(timer);
            }.bind(this), 25000);

        }


        //QRCode.toDataURL(this.state.domain + '/?host=' + response.host_code + '&touchless=1').then((data) => {
        QRCode.toDataURL('https://app.visitorsfortrello.com/?host=' + response.host_code + '&touchless=1').then((data) => {
            this.setState({ qrImageURL: data });
        });




    }

    setBackground (background, color, mode) {
        background = {
            backgroundImage: "url(" + background + ")",
            backgroundBlendMode: mode,
            backgroundSize: 'cover',
            backgroundColor: color,
            minHeight: '100vh',
        }
        this.setState({ background: background});
        document.body.style.backgroundColor = color;
    }

    onBackgroundImageLoaded (url, color, mode) {
        const img = new Image();
        img.src = url;
        img.onloadend = this.setBackground(img.src, color, mode);  //Image has loaded or failed
        return;
    }


    t( string ){

        if(this.state.translations[ string ] && this.state.translations[ string ] !== undefined && this.state.translations[ string ] !== "") {
            return this.convertText(decodeURIComponent(escape(this.state.translations[ string ])));
        } else {
            return string;
        }

    }

    changeLanguage(locale) {

        const data     = JSON.parse(localStorage.getItem('displaySettings'));

        const dataSend = {
            locale: locale,
            client_id: data.id,
            organization_id: data.organization_id
        }

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.domain + '/core/connect.php?action=getTranslations',
            data: dataSend

        }).then((response) => {

            this.setState({ languageDefault: locale });
            this.setState({ translations: response.data });

            this.setStyles();


        });
    }

    setHomeMessage(message) {

        this.setState({ homeMessage: message });

        if(message !== "") {
            var timer = setInterval(function(){
                this.setState({ homeMessage: '' });
                this.setStyles();
                clearInterval(timer);
            }.bind(this), 7000);
        }

    }

    clearHomeMessage() {
        this.changeHomeMessage('').then(function(){
            this.setStyles();
        }.bind(this));


    }

    changeHomeMessage(message){
        return new Promise(function (resolve, reject) {
            this.setState({ homeMessage: message });
            resolve(true);
        }.bind(this));
    }

    setFullScreen() {
        this.setState({isFullscreenEnabled: true});
    }

    changeScreen(screen) {

        this.setState({ screen: screen});
    }

    setStyles(){

        let h  = window.innerHeight

        var mainStyle;

        const element = document.getElementsByClassName('main-content')[0];

        if(typeof(element) != 'undefined' && element != null){

            var offsetHeight    = element.offsetHeight;

            const back      = document.getElementById("back");
            const branding  = document.getElementById("branding");

            var marginTop = (h-offsetHeight)/2;

            // Gør plads til FREE banner
            if(this.state.hasAccount === 1 && this.state.freeSMSPoints === "50" && marginTop < 170){
                marginTop = 170;
            }

            if(marginTop < 40) {
                marginTop = 40;
            }

            if(offsetHeight + marginTop + 75 > h){

                if(typeof(back) != 'undefined' && back != null){
                    back.style.setProperty('position', 'relative');
                }

                if(typeof(branding) != 'undefined' && branding != null) {
                    branding.style.setProperty('position', 'initial');
                    //branding.style.setProperty('margin-bottom', '25px');
                }


            } else {


                if(typeof(back) != 'undefined' && back != null){
                    back.style.setProperty('position', 'absolute');
                }

                if(typeof(branding) != 'undefined' && branding != null) {
                    branding.style.setProperty('position', 'absolute');
                    branding.style.setProperty('bottom', '0px');
                }

            }

            mainStyle = {
                marginTop: marginTop + 'px',
                marginBottom: '25px',
            }

            this.setState({ mainStyle: mainStyle });

            const appStyle = {
                minHeight: (offsetHeight + marginTop) + 'px'
            }

            this.setState({ appStyle: appStyle });

            var timer = setInterval(function(){
                this.toggleBranding();
                clearInterval(timer);
            }.bind(this),500);

        }

    }

    toggleBranding(){

        const branding      = document.getElementById('branding');

        if(typeof(branding) != 'undefined' && branding != null) {

            const brandLabel = document.getElementById("brand-label");

            if (typeof(brandLabel) != 'undefined' && brandLabel != null) {

                const element               = document.getElementsByClassName('main-content')[0];
                var offsetWidth             = element.offsetWidth;
                const brandLabelTextWidth   = document.getElementById("brand-text").clientWidth + 60;

                const info = document.getElementById("info");
                if (typeof(info) != 'undefined' && info != null) {
                    offsetWidth = offsetWidth - info.clientWidth;
                }

                const language = document.getElementById("language");
                if (typeof(language) != 'undefined' && language != null) {
                    offsetWidth = offsetWidth - language.clientWidth;
                }

                if (offsetWidth - brandLabelTextWidth < 15) {
                    document.getElementById("brand-text").classList.add('not-visible');
                } else {
                    document.getElementById("brand-text").classList.remove('not-visible');
                }

            }

            branding.classList.remove("transparent");
        }

    }


convertText(text){

            text = text.split("\n").join("<br>");

            let strong = 0;
            let output = '';

            for (var i = 0; i < text.length; i++) {

                if (text.substr(i, 2) === '**') {

                    i = i + 1;

                    if (strong === 0) {
                        output = output + "<strong>";
                        strong = 1;
                    } else {
                        output = output + "</strong>";
                        strong = 0;
                    }

                } else {
                    output = output + text.substr(i, 1);
                }
            }

            return output;

    }

    handleClosed = () => {
        if (this.state.shouldPauseVideo) {
            const player = this.youtubeRef.current.internalPlayer;
            if (player) {
                player.stopVideo();
            }
        }
    };

    videoPop() {
        const content = (
            <div id="video-container">
                <YouTube
                    ref={this.youtubeRef}
                    videoId="Lr_cs6jo22g"
                    opts={{
                        playerVars: {
                            autoplay: 0,
                            rel: 0,
                        },
                    }}
                    />
            </div>
        );

        PopupboxManager.open({
            content,
            config: {
                titleBar: {
                    enable: true,
                    text: 'Visitors for Trello',
                },
                fadeIn: true,
                fadeInSpeed: 500,
                overlayStyle: {
                    width: '70%', // Set the desired width here
                },
                onClosed: this.handleClosed,
            },
        });
    }

    render() {

        const colorFront    = {
            color: this.state.colors.colorFront,
            textShadow: '0px 4px 3px ' + this.state.colors.colorFrontShadow + this.state.colors.colorFrontShadowTransparency,
            whiteSpace: 'pre-wrap'
        }

        return (
            <div >
                <div id="go-fullscreen" style={this.state.goFullScreenStyle} onClick={() => this.setFullScreen()}><IoIosExpand/></div>

                    <Fullscreen
                        id="app"
                        enabled={this.state.isFullscreenEnabled}
                        onChange={isFullscreenEnabled => this.setState({isFullscreenEnabled})}

                    >

                    <div style={this.state.background}>

                        {this.state.hasAccount === 0 &&
                        <div className="centered-div">
                            <img id="free-version-badge" src={FreeVersionBadge} alt="Free version of Visitors for Trello" onClick={() => this.videoPop()}/>
                            <span className="badge-text" style={ colorFront }>Subscribe to remove this Badge</span>
                        </div>
                    }

                    {this.state.hasAccount === 1 && this.state.freeSMSPoints === "50" &&
                    <div className="centered-div">
                        <img id="free-sms-trial-badge" src={FreeSMSTrialBadge} alt="Free SMS Trial of Visitors for Trello" onClick={() => this.videoPop()}/>
                        <span className="badge-text" style={ colorFront }>Subscribe to remove this Badge</span>
                    </div>
                    }


                    {this.state.screen === 'splashscreen' && (
                        <SplashScreen
                            path                = "/"
                            setup               = {this.setup.bind(this)}
                            hostUrl             = {this.state.hostUrl}
                            colors              = {this.state.colors}
                            background          = {this.state.background}
                            mainStyle           = {this.state.mainStyle}
                            setStyles           = {this.setStyles.bind(this)}
                            />
                    )}
                    {this.state.screen === 'home' && (
                        <Home
                            path                = "/home"
                            domain              = {this.state.domain}
                            colors              = {this.state.colors}
                            background          = {this.state.background}
                            t                   = {this.t.bind(this)}
                            languages           = {this.state.languages}
                            languageSelect      = {this.state.languageSelect}
                            changeLanguage      = {this.changeLanguage.bind(this)}
                            homeMessage         = {this.state.homeMessage}
                            setHomeMessage      = {this.setHomeMessage.bind(this)}
                            clearHomeMessage    = {this.clearHomeMessage.bind(this)}
                            changeScreen        = {this.changeScreen.bind(this)}
                            screen              = {this.state.screen}
                            mainStyle           = {this.state.mainStyle}
                            setStyles           = {this.setStyles.bind(this)}
                            qrReader            = {this.state.qrReader}
                            qrFlip              = {this.state.qrFlip}
                            qrFrontRear         = {this.state.qrFrontRear}
                            qrImage             = {this.state.qrImage}
                            qrImageURL          = {this.state.qrImageURL}
                            touchless           = {this.state.touchless}
                            appStyle            = {this.state.appStyle}
                            />
                    )}
                    {this.state.screen === 'check-in' && (
                        <CheckIn
                            path                = "/check-in"
                            domain              = {this.state.domain}
                            colors              = {this.state.colors}
                            background          = {this.state.background}
                            t                   = {this.t.bind(this)}
                            companyFormField    = {this.state.companyFormField}
                            phoneFormField      = {this.state.phoneFormField}
                            mailFormField       = {this.state.mailFormField}
                            hostFormField       = {this.state.hostFormField}
                            setHomeMessage      = {this.setHomeMessage.bind(this)}
                            languageDefault     = {this.state.languageDefault}
                            phoneCodes          = {this.state.phoneCodes}
                            changeScreen        = {this.changeScreen.bind(this)}
                            screen              = {this.state.screen}
                            rescan              = {this.state.rescan}
                            terms               = {this.state.terms}
                            mainStyle           = {this.state.mainStyle}
                            setStyles           = {this.setStyles.bind(this)}
                            />
                    )}
                    {this.state.screen === 'check-out' && (
                        <CheckOut
                            path                = "/check-out"
                            domain              = {this.state.domain}
                            colors              = {this.state.colors}
                            background          = {this.state.background}
                            t                   = {this.t.bind(this)}
                            setHomeMessage      = {this.setHomeMessage.bind(this)}
                            changeScreen        = {this.changeScreen.bind(this)}
                            screen              = {this.state.screen}
                            rescan              = {this.state.rescan}
                            mainStyle           = {this.state.mainStyle}
                            setStyles           = {this.setStyles.bind(this)}
                            />
                    )}
                    {this.state.screen === 'setup' && (
                        <Setup
                            path                = "/setup"
                            domain              = {this.state.domain}
                            hostUrl            = {this.state.hostUrl}
                            loadClientValues    = {this.loadClientValues.bind(this)}
                            setDisplayValues    = {this.setDisplayValues.bind(this)}
                            t                   = {this.t.bind(this)}
                            languages           = {this.state.languages}
                            languageSelect      = {this.state.languageSelect}
                            changeLanguage      = {this.changeLanguage.bind(this)}
                            changeScreen        = {this.changeScreen.bind(this)}
                            screen              = {this.state.screen}
                            />
                    )}
                    {this.state.screen === 'abort-session' && (
                        <AbortSession
                            path                = "/abort-session"
                            domain              = {this.state.domain}
                            loadClient          = {this.loadClient.bind(this)}
                            t                   = {this.t.bind(this)}
                            languages           = {this.state.languages}
                            languageSelect      = {this.state.languageSelect}
                            changeLanguage      = {this.changeLanguage.bind(this)}
                            changeScreen        = {this.changeScreen.bind(this)}
                            screen              = {this.state.screen}
                            />
                    )}
                    {this.state.screen === 'rescan' && (
                        <Rescan
                            colors              = {this.state.colors}
                            background          = {this.state.background}
                            t                   = {this.t.bind(this)}
                            languages           = {this.state.languages}
                            languageSelect      = {this.state.languageSelect}
                            changeLanguage      = {this.changeLanguage.bind(this)}
                            homeMessage         = {this.state.homeMessage}
                            clearHomeMessage    = {this.clearHomeMessage.bind(this)}
                            screen              = {this.state.screen}
                            />
                    )}

                    <Branding
                        colors              = {this.state.colors}
                        branding            = {this.state.branding}
                        languages           = {this.state.languages}
                        languageSelect      = {this.state.languageSelect}
                        changeLanguage      = {this.changeLanguage.bind(this)}
                        t                   = {this.t.bind(this)}
                        screen              = {this.state.screen}
                        />





                    </div>

                </Fullscreen>

                <PopupboxContainer />
            </div>
        );
    }
}

export default App;

