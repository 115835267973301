import React, { Component } from 'react';
import axios from "axios";
import { FaArrowCircleLeft } from 'react-icons/fa';

import CheckOutPopup from "./CheckOutPopup";
import CheckOutConfirm from "./CheckOutConfirm";

class CheckOut extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            isError: false,
            visitors: [],
            step: 1,
            cardId: '',
            name: '',
            foundVisitors: [],
            showResult: false,
        };

    }

    async fetchData() {

        const data = JSON.parse(localStorage.getItem('displaySettings'));
        data.idList = data.check_in_list_id;
        data.in_out = 'out';

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getVisitors',
            data: data

        }).then((responseObject) => {

            var response = responseObject.data;

            const visitors = [];

            for (var item in response['visitors']) {
                if(response['visitors'].hasOwnProperty(item)){
                    visitors.push(response['visitors'][item]);
                }
            }

            this.setState({ visitors });


        }).catch((error) => {

            console.log(error)

        });

    }

    setCheckOutStyle = () => {
        this.props.setStyles();
    };

    componentDidMount() {

        this.props.setHomeMessage('');

        this.props.setStyles();

        window.addEventListener('resize', this.setCheckOutStyle);
        document.addEventListener('keyup', this.setCheckOutStyle)

        this.fetchData();
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.setCheckOutStyle)
        document.removeEventListener('keyup', this.setCheckOutStyle)

    }



    setError(msg) {
        this.setState(prevState => ({ errors: [...prevState.errors, msg] }));
    }

    clearAllErrors() {
        this.setState({ errors: [] });
    }

    RegExp_escape(s) {
        //return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    };

    async searchVisitors(keyword) {

        const { visitors } = this.state;
        keyword = this.RegExp_escape(keyword.toLowerCase());
        //const pattern = `[A-Za-z.\s]*${keyword}[A-Za-z.\s]*`;
        const pattern = `[A-Za-z.]*${keyword}[A-Za-z.]*`;
        const matchRegex = new RegExp(pattern);
        const foundVisitors = visitors.filter((item, idx) => {
            //console.log("Match: ", matchRegex, pattern, item.name);
            return matchRegex.test(item.name.toLowerCase());
        });
        //console.log("Found Visitors: ", foundVisitors);
        this.setState({ foundVisitors });

    }

    onInputChange(e) {
        const keyword = e.target.value;
        this.searchVisitors(keyword);
    }

    onInput(e) {
        if (e.target.value !== "") this.showPopup();
        else this.hidePopup();
    }

    showPopup() {
        this.setState({ showResult: true });
    }

    hidePopup() {
        this.setState({ showResult: false });
    }

    confirmCheckOut(response) {

        if(response) {

            this.setState({step: 3});

            const data = JSON.parse(localStorage.getItem('displaySettings'));
            data.cardId = this.state.cardId;
            data.idList = data.check_out_list_id;

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.props.domain + '/core/connect.php?action=updateVisitor',
                data: data

            }).then((responseObject) => {

                //var response = responseObject.data;

                var text = this.props.t('#CHECKOUT#').replace('#NAME#', this.state.name);
                this.props.setHomeMessage(text);

                if(this.props.rescan) {
                    this.props.changeScreen('rescan');
                } else {
                    this.props.changeScreen('home');
                }

            }).catch((error) => {

                console.log(error)

            });

        } else {

            this.setState({ cardId: '' });
            this.setState({ name: '' });
            this.hidePopup();
            this.changeStep(1).then(function(){
                this.props.setStyles();
            }.bind(this));


        }
    }

    selectCheckOutVisitor(cardId, name) {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

        this.setState({ cardId: cardId });
        this.setState({ name: name });
        this.setState( { step: 2 });

    }

    changeStep(step){
        return new Promise(function (resolve, reject) {
            this.setState( { step: step });
            resolve(true);
        }.bind(this));
    }

    render() {

        const { foundVisitors, showResult } = this.state;

        const {colors, background, mainStyle}      = this.props;

        const colorFront    = {
            color: colors.colorFront
        }

        const back = {
            color: colors.colorFront,
            cursor: 'pointer',
        }

        const colorFrontShadow    = {
            color: colors.colorFront,
            textShadow:  '0px 4px 3px ' + colors.colorFrontShadow + colors.colorFrontShadowTransparency
        }

        const inputBorder   = {
            border: '2px solid ' + colors.colorInputBorder + colors.colorInputBorderTransparency
        }

        const btnSmall = {
            color: colors.colorBtnText,
            backgroundColor: colors.colorBack + colors.buttonTransparency,
        }

        const colorPopText = {
            color: colors.colorPopText
        }

        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
        }

        const loaderColors = {
            border: '4px solid ' + colors.colorFront,
            borderColor: colors.colorFront + ' transparent transparent transparent',
        }


        return (

            <div id="app-window" style={ background } className="full-height">

                <div id="back">
                    <div style={back} onClick={() => this.props.changeScreen('home')} ><FaArrowCircleLeft /><span className="text">{this.props.t('Back')}</span></div>
                </div>

                <div className="form-checkin">

                    {this.state.step === 3 && (
                        <div className="container-fluid d-flex full-height align-items-center">
                            <div className="row d-flex justify-content-center vw-100">
                                <div className="content col-12 col-lg-6 text-center">
                                    <div id="loading" className="lds-ring">
                                        <div style={ loaderColors }></div>
                                        <div style={ loaderColors }></div>
                                        <div style={ loaderColors }></div>
                                        <div style={ loaderColors }></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.step === 2 && (
                        <div className="container-fluid d-flex">
                            <div className="row d-flex justify-content-center vw-100">
                                <div className="content col-12 col-lg-6">
                                    <CheckOutConfirm
                                        name            = {this.state.name}
                                        confirmCheckOut = {this.confirmCheckOut.bind(this)}
                                        colorFront      = {colorFront}
                                        btnSmall        = {btnSmall}
                                        colorPop        = {colorPop}
                                        colors          = {colors}
                                        colorPopText    = {colorPopText}
                                        t               = {this.props.t.bind(this)}
                                        setStyles       = {this.props.setStyles.bind(this)}
                                        mainStyle       = {mainStyle}
                                        />
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.step === 1 && (
                        <div className="container-fluid d-flex">
                            <div className="row d-flex justify-content-center vw-100">
                                <div className="content col-12 col-lg-6">
                                    <div id="check-out" className="main-content" style={mainStyle}>
                                        <h3 className="text-shadow mb-3" style={colorFrontShadow}>{this.props.t('Check Out')}</h3>
                                        <section className="form-group">
                                            <label
                                                className="form-control-label sr-only"
                                                htmlFor="displayName"
                                                >
                                                Name
                                            </label>

                                            <input
                                                className="form-control p-4"
                                                autoComplete="none"
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder={this.props.t('Your Name')}
                                                onChange={this.onInputChange.bind(this)}
                                                onInput={this.onInput.bind(this)}
                                                style={inputBorder}
                                                />
                                            <CheckOutPopup isOpen={showResult} items={foundVisitors} selectCheckOutVisitor={this.selectCheckOutVisitor.bind(this)} colorPop={colorPop}/>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>

        );
    }
}

export default CheckOut;