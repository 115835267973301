import React, { Component } from 'react';
import { IoMdLogOut } from 'react-icons/io';

class CheckOutPopup extends Component {

    render() {
        const { items, isOpen, colorPop } = this.props;
        //Do not show popup
        if (!isOpen || items.length === 0) return null;
        return (
            <div id="checked-in-list" className="popup">

                    <div className="content" style={colorPop}>
                        <div id="i-am" className="mt-3"></div>
                        {items &&
                        items.map((item, idx) => {
                            return (
                                <div className="guest-visitor mb-1" key={idx} onClick={() => this.props.selectCheckOutVisitor(item.id, item.name)}>
                                    <div className="item" key={idx} id={item.id}>
                                        <IoMdLogOut /> {item.name}
                                    </div>
                                </div>
                            );
                        })}
                        {!items && <div className="warning">Nothing Found!</div>}
                    </div>

            </div>
        );
    }
}

export default CheckOutPopup;