import React, { Component } from 'react';

import Branding from './Branding';

class Rescan extends Component {

    render() {

        const {colors, background, clientInfoText, languages, languageSelect, homeMessage, screen} = this.props;

        const colorFront    = {
            color: colors.colorFront,
            textShadow:  '0px 4px 3px ' + colors.colorFrontShadow + colors.colorFrontShadowTransparency,
            whiteSpace: "pre-wrap"
        }


        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
            whiteSpace: "pre-wrap"
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });


        return (

            <div id="app-window" className="visitor-background" style={ background }>

                <div className="container-fluid d-flex full-height align-items-center">

                    <div className="row d-flex justify-content-center vw-100">


                        {homeMessage !=="" &&
                            <div className="content col-12 col col-sm-10 col-lg-6 text-center" onClick={() => this.props.clearHomeMessage()}>
                                <h3 id="home-message"  style={colorPop} dangerouslySetInnerHTML={{__html: homeMessage}}></h3>
                            </div>

                        }

                        {homeMessage ==="" &&
                            <div id="home-wrapper" className="content col-12 col-sm-12 col-lg-10 text-center">

                                <h3 style={ colorFront }>
                                    {this.props.t("#RESCAN#")}
                                </h3>

                            </div>

                        }


                    </div>

               </div>

               <Branding
                   colorFront       ={colorFront}
                   clientInfoText   ={clientInfoText}
                   languages        ={languages}
                   languageSelect   ={languageSelect}
                   changeLanguage   ={this.props.changeLanguage.bind(this)}
                   t                ={this.props.t.bind(this)}
                   screen           ={screen}
                   />

           </div>

        );
    }
}

export default Rescan;
