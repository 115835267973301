import React, { Component } from 'react';
import axios from "axios";
import { IoMdLogIn } from 'react-icons/io';
import { FaArrowCircleLeft } from 'react-icons/fa';

import Branding from './Branding';

class AbortSession extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };


    }

    abortSession(){

        var local = JSON.parse(localStorage.getItem('displaySettings'));

        var data = {
            id: local.id,
            host_code: local.host_code,
            client_code: '',
            client_accepted: 0
        }

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=disconnectClient',
            data: data

        }).then((response) => {

            localStorage.removeItem('displaySettings');

            this.props.changeScreen('setup');
            /*
            navigate(
                `/setup`
            );
            */


        }).catch((error) => {

            console.log(error)

        });

    }


    render() {

        const {languages, languageSelect} = this.props;

        const back = {
            cursor: 'pointer',
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        return (

            <div id="app-window" className="visitor-background">
                <div className="container-fluid d-flex full-height align-items-center">
                    <div className="row d-flex justify-content-center vw-100">
                        <div className="col-12 col-lg-6">

                            <div id="abort-session" className="text-center">

                                <h3 className="font-weight-light">
                                    <div className="mb-2">{this.props.t('This display is already connected to a host')}.</div>
                                    <div className="mb-4">{this.props.t('Do you want to abort')}?</div>
                                </h3>

                                <button to="/setup" className="btn-admin mr-3" onClick={() => this.props.loadClient()}>
                                    {this.props.t('Cancel')}
                                </button>

                                <button to="/setup" className="btn-admin" onClick={() => this.abortSession()}>
                                    {this.props.t('Abort Session')} <IoMdLogIn/>
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
                <div id="back">
                    <div style={back} onClick={() => this.props.changeScreen('splashscreen')} ><FaArrowCircleLeft /><span className="text">{this.props.t('Back')}</span></div>
                </div>
                <Branding
                    languages={languages}
                    languageSelect={languageSelect}
                    changeLanguage={this.props.changeLanguage.bind(this)}
                    t={this.props.t.bind(this)}
                    />
            </div>

        );
    }
}

export default AbortSession;