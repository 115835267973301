import React, {Component} from 'react';
import { IoMdLogIn } from 'react-icons/io';

class CheckInConfirm extends Component {

    componentDidMount() {
        this.props.setStyles();
    }

    render () {

        const { name, company, guestName, btnSmall, colors, colorPopText, terms, mainStyle} = this.props;


        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
        }

        return (

            <div id="confirm" className="main-content" style={Object.assign({}, mainStyle, colorPop)}>

                <h3 className="text-shadow" style={colorPopText}>{this.props.t('Please confirm')}</h3>

                {guestName === '' && (
                    <div className="mb-3" style={colorPopText}>
                        {this.props.t('Check in as')} { name } { company !== '' && (<span>, { company }</span>)}
                    </div>
                )}

                {guestName !== '' && (
                    <div className="mb-3" style={colorPopText}>
                        {this.props.t('Check in as')} { guestName }
                    </div>
                )}

                {terms === '1' && (
                    <div className="terms mb-3" dangerouslySetInnerHTML={{__html: this.props.t('#TERMS#')}}></div>
                )}

                <button className="btn-medium mr-3" style={btnSmall} type="button" onClick={() => this.props.confirmCheckIn(false)}>
                    {this.props.t('Change')}
                </button>

                <button className="btn-medium" style={btnSmall} type="button" onClick={() => this.props.confirmCheckIn(true)}>
                    {this.props.t('Check In')} <IoMdLogIn/>
                </button>

            </div>

        )
    }
}

export default CheckInConfirm;