import React, { Component } from 'react';
import { IoMdLogIn } from 'react-icons/io';

class CheckInMembersPopup extends Component {

    render() {
        const { items, isOpen, colorPop } = this.props;
        //Do not show popup
        if (!isOpen || items.length === 0) return null;
        return (
            <div id="members" className="popup">
                    <div className="content" style={colorPop}>
                        <div id="i-am" className="mt-3"></div>
                        {items &&
                        items.map((item, idx) => {
                            return (
                                <div className="guest-visitor mt-1" key={idx} onClick={() => this.props.insertMember(item.id, item.fullName)}>
                                    <div className="item" key={idx} id={item.id}>
                                        <IoMdLogIn /> {item.fullName}
                                    </div>
                                </div>
                            );
                        })}
                        {!items && <div className="warning">Nothing Found!</div>}
                    </div>

            </div>
        );
    }
}

export default CheckInMembersPopup;