import React, { Component } from 'react';

import { IoMdLogIn } from 'react-icons/io';
import { FaArrowCircleLeft} from 'react-icons/fa';
import axios from "axios";

import CheckInPopup from "./CheckInPopup";
import CheckInMembersPopup from "./CheckInMembersPopup";
import CheckInConfirm from "./CheckInConfirm";

class CheckIn extends Component {



    constructor(props) {
        super(props);

        this.state = {
            name: '',
            company: '',
            hostId: '',
            hostName: '',
            email: '',
            phone: '',
            guestId: '',
            guestName: '',
            errors: [],
            isError: false,
            visitors: [],
            foundVisitors: [],
            showResultVisitors: false,
            members: [],
            foundMembers: [],
            showResultMembers: false,
            step: 1,
            phoneCode: '',
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


    }

    handleChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }

    handleSubmit(e) {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

        e.preventDefault();
        this.setState( { step: 2});

    }

    scrolldown(ref) {
        const self = this;
        this.refs[ref].measure((ox, oy, width, height, px, py) => {
            self.refs.scrollView.scrollTo({y: oy - 200});
        });
    }

    confirmCheckIn ( response) {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

        if(response) {
            if(this.state.guestId !== "") {
                this.postGuestCheckIn();
            } else {
                this.postCheckIn();
            }

        } else {
            this.setState( { guestId: '' });
            this.setState( { guestName: '' });
            this.changeStep(1).then(function(){
                //this.setState({ checkInStyle: this.setStyles()});
                this.props.setStyles();
            }.bind(this));
        }
    }

    postCheckIn() {

        this.setState({step: 3});

        const data      = JSON.parse(localStorage.getItem('displaySettings'));

        data.name       = this.state.name;
        data.company    = this.state.company;
        data.hostId     = this.state.hostId;
        data.hostName   = this.state.hostName;
        data.email      = this.state.email;

        if(this.state.phone !== '') {
            data.phone      = this.state.phoneCode + ' ' + this.state.phone
        } else {
            data.phone      = '';
        }

        data.idList     = data.check_in_list_id;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=newCard',
            data: data

        }).then((responseObject) => {

            var text = this.props.t('#CHECKIN#').replace('#NAME#', this.state.name);
            this.props.setHomeMessage(text);

            if(this.props.rescan) {
                this.props.changeScreen('rescan');
            } else {
                this.props.changeScreen('home');
            }



        }).catch((error) => {

            console.log(error)

        });

    }

    selectGuestCheckIn(cardId, guestName) {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

        this.setState({guestId: cardId});
        this.setState({guestName: guestName});
        this.setState({step: 2});

    }


    postGuestCheckIn() {

        this.setState({step: 3});

        const data = JSON.parse(localStorage.getItem('displaySettings'));
        data.cardId = this.state.guestId;
        data.idList = data.check_in_list_id;
        data.in_out = 'in';

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=updateVisitor',
            data: data

        }).then((responseObject) => {

            var text =  this.props.t('#CHECKIN#').replace('#NAME#', this.state.guestName);
            this.props.setHomeMessage(text);

            if(this.props.rescan) {
                this.props.changeScreen('rescan');
            } else {
                this.props.changeScreen('home');
            }


        }).catch((error) => {

            console.log(error)

        });

    }

    async fetchData() {

        const data = JSON.parse(localStorage.getItem('displaySettings'));
        data.idList = data.guestlist_id;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getVisitors',
            data: data

        }).then((responseObject) => {

            var response = responseObject.data;

            //console.log(responseObject);

            const visitors = [];
            var item;

            for (item in response['visitors']) {
                if(response['visitors'].hasOwnProperty(item)){
                    visitors.push(response['visitors'][item]);
                }
            }

            this.setState({ visitors });

            const members = [];

            for (item in response['members']) {
                if(response['members'].hasOwnProperty(item)){
                    members.push(response['members'][item]);
                }
            }

            this.setState({ members });


        }).catch((error) => {

            console.log(error)

        });

    }

    setCheckInStyle = () => {
        this.props.setStyles();
    };

    componentDidMount() {

        this.props.setHomeMessage('');

        //this.setState({ checkInStyle: this.setStyles()});
        this.props.setStyles();

        window.addEventListener('resize', this.setCheckInStyle);
        document.addEventListener('keyup', this.setCheckInStyle)

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

        this.fetchData();
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.setCheckInStyle)
        document.removeEventListener('keyup', this.setCheckInStyle)

    }

    changeStep(step){
        return new Promise(function (resolve, reject) {
            this.setState( { step: step });
            resolve(true);
        }.bind(this));
    }

    setError(msg) {
        this.setState(prevState => ({ errors: [...prevState.errors, msg] }));
    }

    clearAllErrors() {
        this.setState({ errors: [] });
    }

    RegExp_escape(s) {
        //return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    };

    async searchVisitors(keyword) {

        const { visitors } = this.state;
        keyword = this.RegExp_escape(keyword.toLowerCase());
        //const pattern = `[A-Za-z.\s]*${keyword}[A-Za-z.\s]*`;
        const pattern = `[A-Za-z.]*${keyword}[A-Za-z.]*`;
        const matchRegex = new RegExp(pattern);
        const foundVisitors = visitors.filter((item, idx) => {
            //console.log("Match: ", matchRegex, pattern, item.name);
            return matchRegex.test(item.name.toLowerCase());
        });
        //console.log("Found Visitors: ", foundVisitors);
        this.setState({foundVisitors});

    }

    onInputChangeVisitors(e) {

        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue });

        const keyword = e.target.value;
        this.searchVisitors(keyword);

    }

    onInputVisitors(e) {
        if (e.target.value !== "") {
            this.showPopupVisitors();
        } else {
            this.hidePopupVisitors();
        }
    }

    showPopupVisitors() {
        this.setState({ showResultVisitors: true });
    }

    hidePopupVisitors() {
        this.setState({ showResultVisitors: false });
    }

    async searchMembers(keyword) {

        const { members } = this.state;

        keyword = this.RegExp_escape(keyword.toLowerCase());
        //const pattern = `[A-Za-z.\s]*${keyword}[A-Za-z.\s]*`;
        const pattern = `[A-Za-z.]*${keyword}[A-Za-z.]*`;
        const matchRegex = new RegExp(pattern);
        const foundMembers = members.filter((item, idx) => {
            //console.log("Match: ", matchRegex, pattern, item.fullName);
            return matchRegex.test(item.fullName.toLowerCase());
        });
        //console.log("Found Visitors: ", foundMembers);
        this.setState({foundMembers});

    }

    onInputChangeMembers(e) {

        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue });

        const keyword = e.target.value;
        this.searchMembers(keyword);
        this.setState({ hostId: '' });

    }

    onInputMembers(e) {
        if (e.target.value !== "") this.showPopupMembers();
        else this.hidePopupMembers();
    }

    showPopupMembers() {
        this.setState({ showResultMembers: true });
    }

    hidePopupMembers() {
        this.setState({ showResultMembers: false });
    }

    insertMember (id, fullName) {
        this.setState({ hostId: id });
        this.setState({ hostName: fullName });
        this.hidePopupMembers();
    }

    hidePopups(){
        this.hidePopupVisitors();
        this.hidePopupMembers();
    }

    render() {

        const { foundVisitors, showResultVisitors } = this.state;
        const { foundMembers, showResultMembers } = this.state;

        const {colors, background, mainStyle } = this.props;
        const {companyFormField, phoneFormField, mailFormField, hostFormField} = this.props;


        const colorFront    = {
            color: colors.colorFront,
        }

        const colorFrontShadow    = {
            color: colors.colorFront,
            textShadow:  '0px 4px 3px ' + colors.colorFrontShadow + colors.colorFrontShadowTransparency
        }

        const back = {
            color: colors.colorFront,
            cursor: 'pointer',
        }

        let inputStyle   = {
            border: '2px solid ' + colors.colorInputBorder + colors.colorInputBorderTransparency
        }

        const btnSmall = {
            color: colors.colorBtnText,
            backgroundColor: colors.colorBack + colors.buttonTransparency,
        }

        const colorPopText = {
            color: colors.colorPopText
        }

        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
        }

        const loaderColors = {
            border: '4px solid ' + colors.colorFront,
            borderColor: colors.colorFront + ' transparent transparent transparent',
        }

        let companyFormFieldStyle;
        let phoneFormFieldStyle;
        let mailFormFieldStyle;
        let hostFormFieldStyle;

        let companyRequired = false;
        let phoneRequired = false;
        let mailRequired = false;
        let hostRequired = false;

        let nameClass = '';
        let companyClass = '';
        let phoneClass = '';
        let mailClass = '';
        let hostClass = '';


        if(this.state.name !== '') {
            nameClass = 'checked';
        }

        if(companyFormField === 0) {
            companyFormFieldStyle   = {display: 'none'}
        } else if(companyFormField === 1)  {
            companyFormFieldStyle   = {display: 'block'}
        } else if(companyFormField === 2) {
            companyFormFieldStyle   = {display: 'block'}
            companyRequired         = true;
            if(this.state.company !== '') {
                companyClass = 'checked';
            }
        }

        if(phoneFormField === 0) {
            phoneFormFieldStyle     = {display: 'none'}
        } else if(phoneFormField === 1)  {
            phoneFormFieldStyle     = {display: 'block'}
        } else if(phoneFormField === 2)  {
            phoneFormFieldStyle     = {display: 'block'}
            phoneRequired           = true;
            if(this.state.phone !== '') {
                phoneClass = 'checked';
            }
        }

        if(mailFormField === 0) {
            mailFormFieldStyle      = {display: 'none'}
        } else if(mailFormField === 1)  {
            mailFormFieldStyle      = {display: 'block'}
        } else if(mailFormField === 2)  {
            mailFormFieldStyle      = {display: 'block'}
            mailRequired            = true;
            if(this.state.email !== '') {
                mailClass = 'checked';
            }
        }

        if(hostFormField === 0) {
            hostFormFieldStyle      = {display: 'none'}
        } else if(hostFormField === 1)  {
            hostFormFieldStyle      = {display: 'block'}
        } else if(hostFormField === 2)  {
            hostFormFieldStyle      = {display: 'block'}
            hostRequired            = true;
            if(this.state.hostName !== '') {
                hostClass = 'checked';
            }
        }

        const { languageDefault, phoneCodes } = this.props;

        var arrayPhoneCodes = [];
        var phoneCode;

        for (phoneCode in phoneCodes) {
            arrayPhoneCodes.push(phoneCodes[phoneCode]['code']);
        }

        if(this.state.phoneCode === '' && phoneCodes[languageDefault.substr(3)] !== undefined) {
            this.phoneCode = '+' + phoneCodes[languageDefault.substr(3)]['code'];
        } else {
            this.phoneCode = this.state.phoneCode;
        }

        return (
            <div id="app-window" style={ background } className="full-height">

                <div id="back" >
                    <div style={back} onClick={() => this.props.changeScreen('home')} ><FaArrowCircleLeft /><span className="text">{this.props.t('Back')}</span></div>
                </div>

                <form onSubmit={this.handleSubmit} className="form-checkin">

                    {this.state.step === 3 && (
                        <div className="container-fluid d-flex full-height align-items-center">
                            <div className="row d-flex justify-content-center vw-100">
                                <div className="content col-12 col-lg-6 text-center">
                                    <div id="loading" className="lds-ring">
                                        <div style={ loaderColors }></div>
                                        <div style={ loaderColors }></div>
                                        <div style={ loaderColors }></div>
                                        <div style={ loaderColors }></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.step === 2 && (
                        <div className="container-fluid d-flex">
                            <div className="row d-flex justify-content-center vw-100">
                                <div className="content col-12 col-lg-6">
                                    <CheckInConfirm
                                        name            = {this.state.name}
                                        company         = {this.state.company}
                                        guestName       = {this.state.guestName}
                                        confirmCheckIn  = {this.confirmCheckIn.bind(this)}
                                        colorFront      = {colorFront}
                                        btnSmall        = {btnSmall}
                                        colors          = {colors}
                                        colorPop        = {colorPop}
                                        colorPopText    = {colorPopText}
                                        terms           = {this.props.terms}
                                        t               = {this.props.t.bind(this)}
                                        setStyles       = {this.props.setStyles.bind(this)}
                                        mainStyle       = {mainStyle}

                                        />
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.step === 1 && (

                        <div id="container-wrapper" className="container-fluid d-flex">
                            <div className="row d-flex justify-content-center vw-100">
                                <div id="content-wrapper" className="content col-12 col-lg-6">

                                    <div id="check-in" className="main-content" style={ mainStyle } >

                                        <h3 className="mb-3 text-shadow" style={colorFrontShadow} >{this.props.t('Check In')}</h3>

                                        <section className="form-group">
                                            <label
                                                className="form-control-label sr-only"
                                                htmlFor="name"
                                                >
                                                Name
                                            </label>
                                            <input
                                                required
                                                className       = {"form-control pl-3 mb-1 " + nameClass}
                                                autoComplete    = "off"
                                                type            = "text"
                                                id              = "name"
                                                name            = "name"
                                                placeholder     = {this.props.t('Your Name')}
                                                value           = {this.state.name}
                                                onChange        = {this.onInputChangeVisitors.bind(this)}
                                                onInput         = {this.onInputVisitors.bind(this)}
                                                onFocus         = {this.hidePopupMembers.bind(this)}
                                                style           = {inputStyle}
                                                />
                                            <CheckInPopup isOpen={showResultVisitors} items={foundVisitors} selectGuestCheckIn={this.selectGuestCheckIn.bind(this)} colorPop={colorPop}/>
                                        </section>

                                        <section className="form-group" style={companyFormFieldStyle}>
                                            <label
                                                className       = "form-control-label sr-only"
                                                htmlFor         = "Company"
                                                >
                                                Email
                                            </label>
                                            <input
                                                required        = {companyRequired}
                                                className       = {"form-control pl-3 " + companyClass}
                                                type            = "text"
                                                id              = "company"
                                                name            = "company"
                                                placeholder     = {this.props.t('Company')}
                                                value           = {this.state.company}
                                                onChange        = {this.handleChange}
                                                onFocus         = {this.hidePopups.bind(this)}
                                                style           = {inputStyle}
                                                />
                                        </section>

                                        <section className="form-group"  style={phoneFormFieldStyle}>
                                            <label
                                                className       = "form-control-label sr-only"
                                                htmlFor         = "Phone"
                                                >
                                                Phone
                                            </label>
                                            <div id="phone-code-wrapper">
                                                <select
                                                    className   = "form-control pl-3"
                                                    type        = "text"
                                                    id          = "phoneCode"
                                                    name        = "phoneCode"
                                                    value       = {this.phoneCode}
                                                    onChange    = {this.handleChange}
                                                    onFocus     = {this.hidePopups.bind(this)}
                                                    style       = {inputStyle}
                                                    >
                                                    { arrayPhoneCodes.map((element, index) => <option key={index}>+{element}</option>) }
                                                </select>
                                            </div>
                                            <input
                                                required        = {phoneRequired}
                                                className       = {"form-control pl-3 " + phoneClass}
                                                type            = "text"
                                                id              = "phone"
                                                name            = "phone"
                                                placeholder     = {this.props.t('Phone')}
                                                value           = {this.state.phone}
                                                onChange        = {this.handleChange}
                                                onFocus         = {this.hidePopups.bind(this)}
                                                style           = {inputStyle}
                                                />
                                        </section>

                                        <section className="form-group" style={mailFormFieldStyle}>
                                            <label
                                                className       = "form-control-label sr-only"
                                                htmlFor         = "Email"
                                                >
                                                Email
                                            </label>
                                            <input
                                                required        = {mailRequired}
                                                className       = {"form-control pl-3 " + mailClass}
                                                type            = "email"
                                                id              = "email"
                                                name            = "email"
                                                placeholder     = {this.props.t('Email')}
                                                value           = {this.state.email}
                                                onChange        = {this.handleChange}
                                                onFocus         = {this.hidePopups.bind(this)}
                                                style           = {inputStyle}
                                                />
                                        </section>

                                        <section className="form-group" style={hostFormFieldStyle}>
                                            <label
                                                className       = "form-control-label sr-only"
                                                htmlFor         = "hostName"
                                                >
                                                Host
                                            </label>
                                            <input
                                                type            = "hidden"
                                                id              = "hostId"
                                                name            = "hostId"
                                                value           = {this.state.hostId}
                                                />
                                            <input
                                                required        = {hostRequired}
                                                className       = {"form-control pl-3 " + hostClass}
                                                type            = "text"
                                                id              = "hostName"
                                                name            = "hostName"
                                                placeholder     = {this.props.t('Here to meet with')}
                                                value           = {this.state.hostName}
                                                onChange        = {this.onInputChangeMembers.bind(this)}
                                                onInput         = {this.onInputMembers.bind(this)}
                                                onFocus         = {this.hidePopupVisitors.bind(this)}
                                                style           = {inputStyle}
                                                />
                                            <CheckInMembersPopup isOpen={showResultMembers} items={foundMembers} insertMember={this.insertMember.bind(this)} colorPop={colorPop} />
                                        </section>

                                        <div className="form-group text-right mt-4">
                                            <button className="btn-medium" type="submit" style={btnSmall}>
                                                {this.props.t('Check In')} <IoMdLogIn/>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    )}


                </form>

            </div>
        );
    }
}

export default CheckIn;