import React, {Component} from 'react';
import { IoMdLogOut } from 'react-icons/io';

class CheckOutConfirm extends Component {

    componentDidMount() {
        this.props.setStyles();
    }


    render () {

        const { name, btnSmall, colorPop, colorPopText, mainStyle } = this.props;

        return (

            <div id="confirm" className="main-content" style={Object.assign({}, mainStyle, colorPop)}>

                <h3 className="text-shadow" style={colorPopText}>{this.props.t('Please confirm')}</h3>

                <div className="mb-3" style={colorPopText}>
                    {this.props.t('Check out as')} { name }
                </div>

                <button className="btn-medium mr-3" type="button" style={btnSmall} onClick={() => this.props.confirmCheckOut(false)}>
                    {this.props.t('Change')}
                </button>


                <button className="btn-medium" type="button" style={btnSmall} onClick={() => this.props.confirmCheckOut(true)}>
                    {this.props.t('Check Out')} <IoMdLogOut/>
                </button>

            </div>

        )
    }
}

export default CheckOutConfirm;